<div class="mat-dialog-container">
    <!-- Dialog header -->
    <div fxLayout="row" fxLayoutGap="10px">
        <h2 mat-dialog-title style="width:100%; text-align: start;">Criar novo usuário</h2>
    </div>

    <!-- Modal content -->
    <mat-dialog-content>
        <div fxLayout="column">
            <div fxLayout="row">
                <mat-form-field fxFlex="grow">
                    <mat-label>Usuario</mat-label>
                    <input matInput type="text" [(ngModel)]="data.usuario">
                </mat-form-field>
                <span fxLayoutAlign="end center">@{{data.dominio}}</span>
            </div>
            <mat-form-field>
                <mat-label>Senha</mat-label>
                <input matInput type="password" [(ngModel)]="data.senha">
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <!-- Modal actions -->
    <mat-dialog-actions fxLayoutAlign="end center">
        <div fxLayout="row">
            <button mat-button class="btn btn-primary" (click)="onCriar()" cdkFocusInitial>CRIAR</button>
            <button mat-button class="btn btn-danger" (click)="onCancelar()">CANCELAR</button>
        </div>
    </mat-dialog-actions>
</div>
