<div class="container" fxLayout="column">
    <mat-expansion-panel class="card" [expanded]="true" *ngIf="data" style="margin-top: -25px;">
        <mat-label *ngIf="title">{{title}}</mat-label>
        <mat-dialog-content style="margin-bottom: -50px;">
            <mat-tab-group mat-align-tabs="center" style="padding-top: 10px;">
                <mat-tab label="Detalhes">
                    <div style="padding-top: 10px;">
                        <mat-form-field style="display: contents;">
                            <mat-label>Nome</mat-label>
                            <input matInput disabled
                                value="{{data.nome !== '' && data.nome !== null ? data.nome : 'N/D' }}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>Tipo</mat-label>
                            <input matInput disabled
                                value="{{data.tipoParceiroDesc !== '' && data.tipoParceiroDesc !== null ? data.tipoParceiroDesc : 'N/D'}}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>CPF/CNPJ</mat-label>
                            <input matInput disabled
                                value="{{ data.cpfCnpj && data.cpfCnpj.length === 11 ? mascaraCPF(data.cpfCnpj) : mascaraCNPJ(data.cpfCnpj) }} ">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>Endereço</mat-label>
                            <input matInput disabled
                                value="{{data.endereco !== '' && data.endereco !== null ? data.endereco : 'N/D'}}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>Cidade</mat-label>
                            <input matInput disabled
                                value="{{data.cidade !== '' && data.cidade !== null ? data.cidade : 'N/D'}}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>CEP</mat-label>
                            <input matInput disabled
                                value="{{data.cep !== '' && data.cep !== null ? data.cep : 'N/D'}}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>Inscriçao Estadual</mat-label>
                            <input matInput disabled
                                value="{{data.inscEstadual !== '' && data.inscEstadual !== null ? data.inscEstadual : 'N/D'}}">
                        </mat-form-field>

                        <mat-form-field style="display: contents;">
                            <mat-label>Observação</mat-label>
                            <textarea matInput disabled
                                value="{{ data.observacao !== '' && data.observacao !== null ? data.observacao : 'N/D' }}"
                            ></textarea>
                        </mat-form-field>
                    </div>
                </mat-tab>
                <mat-tab label="Contatos">
                    <form action="" fxLayout="row" *ngIf="data && data.contatos && data.contatos.length > 0">
                        <mat-form-field fxFlex="30%">
                            <input matInput placeholder="Pesquisar" value="" (keyup)="applyFilter($event.target.value)">
                        </mat-form-field>
                        <mat-icon class="mat-search" svgIcon="search"
                            style="position: relative; top: 18px; left: -23px;" aria-hidden="false"
                            aria-label="Ícone de busca">
                        </mat-icon>
                    </form>

                    <div style="overflow-x:auto;" *ngIf="data && data.contatos && data.contatos.length > 0">
                        <table mat-table matSort id="grid-home" style="width: 100%;" [dataSource]="data.contatos">

                            <ng-container matColumnDef="Nome" style="padding-left: 330px;">
                                <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Nome"
                                    style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    Nome
                                </th>
                                <td class="reducer" mat-cell *matCellDef="let element" matTooltip="{{element.Nome}}"
                                    style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    {{ element.nome ? element.nome : 'N/D' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Telefone" style="padding-left: 330px;">
                                <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header
                                    matTooltip="Telefone"
                                    style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    Telefone
                                </th>
                                <td class="reducer" mat-cell *matCellDef="let element" matTooltip="{{element.Telefone}}"
                                    style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    {{ element.telefone ? element.telefone : 'N/D' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Celular" style="padding-left: 330px;">
                                <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header
                                    matTooltip="Celular"
                                    style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    Celular
                                </th>
                                <td class="reducer" mat-cell *matCellDef="let element" matTooltip="{{element.Celular}}"
                                    style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    {{ element.celular ? element.celular : 'N/D' }}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="Email" style="padding-left: 330px;">
                                <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Email"
                                    style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    Email
                                </th>
                                <td class="reducer" mat-cell *matCellDef="let element" matTooltip="{{element.Email}}"
                                    style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    {{ element.email ? element.email : 'N/D' }}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="Observacao" style="padding-left: 330px;">
                                <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header
                                    matTooltip="Observacao"
                                    style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    Observacão
                                </th>
                                <td class="reducer" mat-cell *matCellDef="let element"
                                    matTooltip="{{element.Observacao}}"
                                    style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                                    {{ element.observacao ? element.observacao : 'N/D' }}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-table"></tr>
                        </table>
                    </div>
                    <div class="large-box mat-elevation-z4" *ngIf="data && data.contatos && data.contatos.length === 0">
                        O representante selecionado não possui contatos para mostrar.
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-dialog-content>
        <mat-dialog-actions align="end" style="margin-bottom: -5px;">
            <button *ngIf="mostrarBotoes.verificar" mat-button cdkFocusInitial (click)="onVerificarRecusa()">Verificar Recusa</button>
            <button *ngIf="mostrarBotoes.recusar" mat-button cdkFocusInitial (click)="onRecusarCadastro()">Recusar Cadastro</button>
            <button *ngIf="mostrarBotoes.confirmar" mat-button cdkFocusInitial (click)="onConfirmarCadastro()">Confirmar Cadastro ERP</button>
            <button mat-button mat-dialog-close (click)="onOk()" *ngIf="eventResult || data.situacao === 'CAD.OK' || data.situacao === 'CAD.REC'">OK</button>
            <button mat-button mat-dialog-close (click)="onOk()" *ngIf="!eventResult && data.situacao !== 'CAD.OK' && data.situacao !== 'CAD.REC'">Cancelar</button>
        </mat-dialog-actions>
    </mat-expansion-panel>
</div>
