<div class="mat-dialog-container">
    <!-- Dialog header -->
    <div fxLayout="row" fxLayoutGap="10px">
        <h2 mat-dialog-title style="width:100%; text-align: start;" *ngIf="title">{{title}}</h2>
    </div>

    <!-- Modal content -->
    <mat-dialog-content *ngIf="data.event !== 'VER.REC'">
        <form class="form">          
            <mat-form-field class="full-width" appearance="fill">
              <mat-label> {{ labelTitle }} </mat-label>
              <textarea matInput [formControl]="errControl" (keyup)="onChangeTextarea($event.target.value)" required></textarea>
              <mat-error *ngIf="errControl.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
          </form>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="data.event === 'VER.REC'">
        <form class="form">          
            <mat-form-field class="full-width" appearance="fill">
              <mat-label> {{ labelTitle }} </mat-label>
              <textarea matInput disabled>{{textarea}}</textarea>
            </mat-form-field>
          </form>
    </mat-dialog-content>
    <!-- Modal actions -->
    <mat-dialog-actions fxLayoutAlign="end center">
        <div fxLayout="row">
            <button mat-button class="btn btn-primary" (click)="onOk()" cdkFocusInitial>{{ buttonTitle }}</button>
            <button mat-button mat-dialog-close class="btn btn-danger" *ngIf="data.event !== 'VER.REC'">CANCELAR</button>
        </div>
    </mat-dialog-actions>
</div>