import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { EventosPreCadastroComponent } from '../dialogs/eventos-pre-cadastro/eventos-pre-cadastro.component';
import { MonitorPreCadastroDetalheComponent } from '../dialogs/monitor-pre-cadastro-detalhe/monitor-pre-cadastro-detalhe.component';
import { AuthService } from './auth.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class PreCadastroService {
  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private loader: LoaderService,
    private auth: AuthService) { }

  public getAreasAtuacao(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.API_ENDPOINT}/api/v1/areasAtuacao`);
  }

  public getPreCadastroRel(areasAtuacaoId: string) {
    return this.httpClient.get<any>(`${env.API_ENDPOINT}/api/v1/preCadastro/${areasAtuacaoId}`);
  }

  public getPreCadastroPage(
    areasAtuacaoId: string,
    filter: string = '',
    sortDirection: string = 'asc',
    pageIndex: number = 1,
    pageSize: number = 30,
  ) {
    const querys = `${areasAtuacaoId}?sort=${sortDirection}&limit=${pageSize}&skip=${++pageIndex}&filter=${filter}`;
    return this.httpClient.get<any>(`${env.API_ENDPOINT}/api/v1/pre-cadastros/${querys}`);
  }

  public async showDetails(dataPicked, areaAtuacao) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      dataPicked
    };
    dialogConfig.maxWidth = '70%';
    dialogConfig.minWidth = '70%';
    dialogConfig.height = '600px';
    dialogConfig.maxHeight = 'auto';
    dialogConfig.minHeight = 'auto';
    const dialogRet = await this.dialog.open(MonitorPreCadastroDetalheComponent, dialogConfig).afterClosed().toPromise();

    if (dialogRet) {
      this.loader.show();
      try {
        return dialogRet;
      } catch (error) {
        if (error.error && error.error.message) {
          this.toastr.error(error.error.message);
        }
      } finally {
        this.loader.hide();
      }

    }
  }

  public async performeEvento(event: any, doc: any) {
    const docUp = JSON.parse(JSON.stringify(doc));
    const usuario = this.auth.getUserAttibute('name');
    const data = new Date();
    const dialogConfig = new MatDialogConfig();
    const acao = event === 'REC.PCAD' ? 'Recusado' : 'Confirmado';
    const acaoMessage = event === 'REC.PCAD' ? 'recusar' : 'confirmar';

    if (event === 'VER.REC') {
      const mensagem = doc.mensagem;
      dialogConfig.data = {
        event,
        mensagem
      };
    } else {
      dialogConfig.data = {
        event
      };
    }

    dialogConfig.minWidth = '30%';
    const dialogRet = await this.dialog.open(EventosPreCadastroComponent, dialogConfig).afterClosed().toPromise();
    if (dialogRet && event !== 'VER.REC') {
      try {
        docUp.mensagem = dialogRet;
        docUp.usuarioADM = usuario;
        docUp.dataRetorno = new Date(data).toISOString().substring(0, 19);
        docUp.situacao = event === 'REC.PCAD' ? 'CAD.REC' : 'CAD.OK';

        const request = {
          preCadastroDoc: docUp
        };

        const req = await this.httpClient.put(`${env.API_ENDPOINT}/api/v1/pre-cadastros/${docUp._id}`, request).toPromise();

        if (req) {
          this.toastr.success(`Pré-Cadastro ${acao} com sucesso!!!`);
          return docUp;
        } else {
          this.toastr.error(`Erro ao ${acaoMessage} o Pré-Cadastro!!!`);
          return undefined;
        }
      }
      catch (error) {
        if (error.error && error.error.message) {
          this.toastr.error(error.error.message);
          return false;
        }
        else {
          this.toastr.error(`Erro ao ${acaoMessage} o Pré-Cadastro!!!`);
          return false;
        }
      }
    } else {
      return false;
    }
  }

}
