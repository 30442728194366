import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface AlterarSenhaDialogData {
  usuario: string,
  senha: string
};

@Component({
  selector: 'app-alterar-senha-dialog',
  templateUrl: './alterar-senha-dialog.component.html',
  styleUrls: ['./alterar-senha-dialog.component.scss']
})
export class AlterarSenhaDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlterarSenhaDialogData,
    public dialogRef: MatDialogRef<AlterarSenhaDialogData>,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer) {

      iconRegistry.addSvgIcon('close', sanitizer.bypassSecurityTrustResourceUrl('/assets/img/close.svg'));
    }

  ngOnInit(): void {
  }

  onCancelar(): void {
    this.dialogRef.close(null);
  }

  onAlterar(): void {
    this.dialogRef.close(this.data);
  }

}
