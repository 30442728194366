import { ToastrService } from 'ngx-toastr';
import { ProdutoImagemDialogComponent } from './../dialogs/produto-imagem-dialog/produto-imagem-dialog.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { environment as env } from 'src/environments/environment';
import { Produto, ProdutoImagem } from '../models/produto';
@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private toastr: ToastrService) { }

  /**
   * Método para recuperar os produtos, retorna um observador dos produtos.
   * o get está separado do método por se tratar de um accessor, facilitador.
   * @returns observable da api
   */
  public get produtos(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.API_ENDPOINT}/api/v1/produtos/all`);
  }

  /**
   * Método para recuperar o produto imagem caso exista
   * @param produtoId identificador do produto
   * @returns retorna um observador da api
   */
  public getProdutoImagem(produtoId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${env.API_ENDPOINT}/api/v1/produtos/${produtoId}`);
  }

  /**
   * Método para abrir o dialog e recuperar o resultado do mesmo, também é responsável por atualizar ou inserir o produto imagem.
   * @param produtoId identificador do produto
   * @param imagens As imagens que já existem neste produto
   */
  public async alterarImagens(produtoId: string, imagens: ProdutoImagem): Promise<any> {
    /* Define as configurações do dialog */
    const dialogConfig = new MatDialogConfig();
    /* Dados a serem enviados para o component */
    dialogConfig.data = { produtoId, imagens };
    /* Tamanhos do dialog */
    dialogConfig.width = '70%';
    dialogConfig.height = '90%';
    dialogConfig.maxHeight = '90%';
    // abre o dialog e aguarda o dialogRef.close
    const dialogReturn = await this.dialog.open(ProdutoImagemDialogComponent, dialogConfig).afterClosed().toPromise();
    // Se houver valor:
    if (dialogReturn) {
      // envia as imagens para o couch
      await this.postProdutoImagem(dialogReturn);
    }
  }

  /**
   * Envia o documento produto imagem para o couchDB
   * @param data dados que vieram do dialog
   */
  private async postProdutoImagem(data: ProdutoImagem): Promise<void> {
    // Normaliza o documento
    const produtoImagem = this.normalizeProdutoImagem(data);
    // Seta o parâmetro
    let params = new HttpParams();
    params = params.append('produtoImagem', produtoImagem);
    // Chamada da api
    await this.httpClient.post(`${env.API_ENDPOINT}/api/v1/produtos/imagem`, produtoImagem).toPromise()
      .then((result: any) => {
        this.toastr.success(result.message, 'Sucesso!');
      }).catch((err) => {
        this.toastr.error('Imagem não inserida.', 'Erro!');
      });
  }

  /**
   * Método para normalizar o documento produto imagem para ser enviado para a api
   * @param data produto imagem
   * @returns produto imagem as document
   */
  private normalizeProdutoImagem(data: ProdutoImagem): any {
    return {
      produtoImagem: {
        _id: `produtoImagem-${data.produtoId}`,
        imagens: data.imagens
      }
    };
  }

}
