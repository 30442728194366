<h1 mat-dialog-title>Um erro ocorreu!</h1>
<div mat-dialog-content>
  <p *ngIf="data.status">
    <b>Código do Erro: {{ data.status }}</b>
  </p>
  <p>Algo deu errado!</p>
  <p>
    {{ data?.message }}
  </p>
  <p>
    {{ data?.solucao }}
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close class="error-close-button">Fechar</button>
</div>
