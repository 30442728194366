import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';

import { environment as env } from 'src/environments/environment';
import { ErroService } from './erro.service';

export interface SalesConConfig {
  dominio: string;
  loginsAdquiridos: number;
  grupo: string;
  delecaoUsuario: any,
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private config: SalesConConfig;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  constructor(private _http: HttpClient, private _msalService: MsalService , private _erroService: ErroService) { }

  public async getConfig(): Promise<SalesConConfig> {
    try {
      if (!this.config) {
        const cfgFromApi = await this._http.get<any>(`${env.API_ENDPOINT}/api/v2/config`).toPromise();
        if (cfgFromApi) {
          this.config = {
            grupo: cfgFromApi.grupo,
            dominio: cfgFromApi.dominio,
            loginsAdquiridos: cfgFromApi.loginsAdquiridos,
            delecaoUsuario: cfgFromApi.delecaoUsuario,
          };
        } else {
          console.error('Erro ao recuperar dados da API');
        }
      }
      return this.config;
    } catch (error) {
      if (error.error && error.error instanceof(ProgressEvent)) {
        if (error.name === 'HttpErrorResponse') {
          this._erroService.eventoErro(
            'ERR.API',
            'Erro de comunicação com a API',
            'Contate o administrador do sistema e informe o código de erro.'
          );
          this._showErrorDialog();
        }
        return;
      }
      this._handlerErrors(error);
      this._showErrorDialog();
    }
  }

  /**
   * Exibe diálogo de erro
   */
  private _showErrorDialog() {
    this._erroService.openErrorDialog()
    .subscribe((closed) => {
      if (closed) {
        // quando o usuário fecha o dialogo faz alguma coisa
        this._msalService.logout();
      }
    });
  }

  /**
   * Tratamento de erros
   * @param error objeto de erro
   */
  private _handlerErrors(error: any): void {
    if (error.error && error.error.code.startsWith('INVALID_TOKEN') && error.status === 500) { // api
      this._erroService.eventoErro(
        'ERR.API',
        'O usuário não está atribuído a nenhum grupo válido',
        'Contate o administrador do sistema e informe o código de erro.'
      );
      return;
    } else if (error.error && error.error.code.startsWith('INVALID_TOKEN')) {
      this._erroService.eventoErro(
        'ERR.AAD',
        'Ocorreu um problema na autenticação com o Active Directory.',
        'login',
        'Contate o administrador do sistema e informe o código de erro.'
      );
      return;
    } else if (error.error && error.error.code.startsWith('GROUP_CONFIG_NOT_FOUND')) {
      this._erroService.eventoErro(
        'ERR.CONFIG',
        'Documento de configuração inexistente para o grupo.',
        'login',
        'Contate o administrador do sistema e informe o código de erro.'
      );
      return;
    } else if (error.errorCode && error.errorCode.startsWith('interaction_required') ||
               error.errorCode && error.errorCode.startsWith('login_required')) {
      if (error.name && error.name.startsWith('InteractionRequiredAuthError')) {
        this._erroService.eventoErro(
          'ERR.ANON',
          'Houve um problema com a autenticação do seu usuário.',
          'login',
          'Caso esteja em uma aba anônima feche e abra uma aba normal, caso o erro persista\
          contate o administrador do sistema e informe o código de erro.'
        );
      }
      return;
    } else {
      this._erroService.eventoErro(
        'ERR.UNDEF',
        'Houve um problema mas não conseguimos identificá-lo.',
        'login',
        'Provavelmente você está em uma aba anônima, feche e abra em uma aba normal.'
      );
    }
  }
}
