<div class="container" fxLayout="column" style="height: 100%; width: 100%;">
    <mat-expansion-panel class="card" [expanded]="true">
        <div fxLayout="row">
            <div fxFlex="60">
                <mat-panel-title>Representantes</mat-panel-title>
                <form action="" fxLayout="row ">
                    <mat-form-field fxFlex="60">
                        <input matInput placeholder="Pesquisar" value="" [(ngModel)]="pesquisaFld"
                            (ngModelChange)="filter$.next($event)" [ngModelOptions]="{standalone: true}">
                    </mat-form-field>
                    <mat-icon class="mat-search" svgIcon="search" style="position: relative; top: 18px; left: -23px;"
                        aria-hidden="false" aria-label="Ícone de busca">
                    </mat-icon>
                </form>
            </div>
            <div fxFlex="40">
                <mat-panel-title>Controle Logins</mat-panel-title>
                <p>
                    <span class="mr-25" id="adquiridos" [matBadge]="logins.adquiridos" matBadgeOverlap=false
                        color="primary" matBadgePosition="above before">
                        Adquiridos
                    </span>
                    <span class="mr-25" id="utilizados" [matBadge]="logins.utilizados" matBadgeOverlap=false color="primary"
                        matBadgePosition="above before">
                        Utilizados
                    </span>
                    <span id="disponiveis" [matBadge]="logins.disponiveis" matBadgeOverlap=false color="primary"
                        matBadgePosition="above before">
                        Disponíveis
                    </span>
                </p>
            </div>
        </div>

        <div style="overflow-x:auto;">
            <table mat-table matSort id="grid-home" style="width: 100%;" [dataSource]="dataSource">

                <!-- Tabela principal com lista de notas no Monitor -->

                <!--Checkbox-->
                <!-- Desativado até implementação de função -->
                <!-- <ng-container matColumnDef="check">
                    <th class="reducer" mat-header-cell *matHeaderCellDef style="width: 0.5%; padding-right: 5px;">
                        <div class="sel">
                            <mat-checkbox></mat-checkbox>
                        </div>
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element" style="width: 0.5%; padding-right: 5px;">
                        <div class="sel">
                            <mat-checkbox></mat-checkbox>
                        </div>
                    </td>
                </ng-container> -->

                <!-- RepresentanteId Column -->
                <ng-container matColumnDef="RepresentanteId" style="padding-left: 330px;">
                    <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Código"
                        style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        Código
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element"
                        [ngClass]="{'linha-excluida': element.status == 'Excluído' }"
                        matTooltip="{{element.representanteId}}"
                        style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        {{element.representanteId}}
                    </td>
                </ng-container>

                <!-- Nome Column -->
                <ng-container matColumnDef="Nome">
                    <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Nome"
                        style="width: 30%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        Nome
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element"
                        [ngClass]="{'linha-excluida': element.status == 'Excluído' }" matTooltip="{{element.nome}}"
                        style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        {{element.nome}}
                    </td>
                </ng-container>

                <!-- Usuario Column -->
                <ng-container matColumnDef="Usuario">
                    <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Usuario"
                        style="width: 30%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        Usuário
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element"
                        [ngClass]="{'linha-excluida': element.status == 'Excluído' }" matTooltip="{{element.usuario}}"
                        style="padding-right: 5px; margin-right:5px !important;">
                        {{element.usuario}}
                    </td>
                </ng-container>

                <!-- Ações Column -->
                <ng-container matColumnDef="Acoes">
                    <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Ações"
                        style="width: 10%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        Ações
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element"
                        [ngClass]="{'linha-excluida': element.status == 'Excluído' }"
                        style="padding-right: 5px; margin-right:5px !important;">
                        <div fxLayout="row">
                            <div style="width: 48px; height: 31px;">
                                <mat-icon class="mat-button" svgIcon="person-add" style="position: relative; top: 5px;"
                                    (click)="criarUsuario(element)" aria-hidden="false" aria-label="Criar Usuário"
                                    *ngIf="!element.usuario && permissaoCriarUsuario" matTooltip="Criar Usuário">
                                </mat-icon>
                            </div>
                            <div style="width: 48px; height: 31px;">
                                <mat-icon class="mat-button" svgIcon="lock" style="position: relative; top: 5px;"
                                    (click)="alterarSenha(element)" aria-hidden="false" aria-label="Alterar Senha"
                                    *ngIf="element.usuario" matTooltip="Alterar Senha">
                                </mat-icon>
                            </div>
                            <div style="width: 48px; height: 31px;">
                                <mat-icon class="mat-button" svgIcon="person-remove"
                                    style="position: relative; top: 5px;" (click)="excluirUsuario(element)"
                                    aria-hidden="false" aria-label="Excluir Usuário" *ngIf="element.usuario"
                                    matTooltip="Excluir Usuário">
                                </mat-icon>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Coluna com botão de ações -->
                <!-- Desativado até implementação de função -->
                <!-- <ng-container matColumnDef="acoes">
                    <th class="reducer" mat-header-cell *matHeaderCellDef sticky style="width: 0.5%; padding: 0px;">
                        Ações </th>
                    <td class="reducer" mat-cell *matCellDef="let element"> -->
                <!-- <a href="" class="btn-default" > -->
                <!-- <mat-icon svgIcon="download" aria-hidden="false" style="cursor"
                            aria-label="Example thumbs up SVG icon">
                        </mat-icon> -->
                <!-- </a> -->
                <!-- [routerLink]="['/sintese-processo']" -->
                <!-- <a href="" class="btn-default" > -->
                <!-- </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-table"></tr>
            </table>
        </div>
        <!--(click)="goToDoc(row)"-->
        <!-- <mat-paginator [pageSizeOptions]="[5, 30, 20]" showFirstLastButtons></mat-paginator> -->
    </mat-expansion-panel>
</div>
