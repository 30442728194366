<div class="mat-dialog-container">
    <!-- Dialog header -->
    <div fxLayout="row" fxLayoutGap="10px">
        <h2 mat-dialog-title style="width:100%; text-align: start;">Alterar Senha</h2>
    </div>

    <!-- Modal content -->
    <mat-dialog-content>
        <div fxLayout="column">
            <div fxLayout="column" style="padding-bottom: 20px;">
                <mat-label style="color: var(--color-fg-1) !important; font-size: 14px !important;">Usuário</mat-label>
                <span>{{data.usuario}}</span>
            </div>
            <mat-form-field>
                <mat-label>Senha</mat-label>
                <input matInput type="password" [(ngModel)]="data.senha">
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <!-- Modal actions -->
    <mat-dialog-actions fxLayoutAlign="end center">
        <div fxLayout="row">
            <button mat-button class="btn btn-primary" (click)="onAlterar()" cdkFocusInitial>ALTERAR</button>
            <button mat-button class="btn btn-danger" (click)="onCancelar()">CANCELAR</button>
        </div>
    </mat-dialog-actions>
</div>
