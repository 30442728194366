export const environment = {
  production: false,
  AMBIENTE: 'HOM',
  AMBIENTE_TOOLTIP: 'Ambiente de homologação',
  API_ENDPOINT: 'https://api.dts.salescon.com.br/main',
  API_SCOPE: 'https://salescon-api-hom.azurewebsites.net/user_impersonation',
  AZURE_AD_CLIENT_ID: '148e187e-f35a-42e7-9b40-4f281c0f622e',
  AZURE_ID_AUTHORITY: 'https://login.microsoftonline.com/fcaa42a5-75e0-430e-8bdd-deb0c4e189a0/',
  REDIRECT_URI: 'https://adm.hom.salescon.com.br/main/'
};
