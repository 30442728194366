<div class="container" fxLayout="column" style="height: 100%; width: 100%;">
  <mat-expansion-panel class="card" [expanded]="true">
    <mat-panel-title>Pré-Cadastro de Parceiros</mat-panel-title>
    <div *ngIf="areasAtuacao; else loading">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Area de Atuação</mat-label>
        <mat-select [formControl]="areasAtuacaoControl" (ngModelChange)="onSelectAreaAtuacao($event)" required>
          <mat-option *ngFor="let areaAtuacao of areasAtuacao" [value]="areaAtuacao">
            {{areaAtuacao.descricao}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="areasAtuacaoControl.hasError('required')">Por favor, selecione uma area de atuação
        </mat-error>
      </mat-form-field>

      <form action="" fxLayout="row" *ngIf="areasAtuacaoControl.value">
        <mat-form-field fxFlex="30%">
          <input [formControl]="inputControl" matInput placeholder="Pesquisar">
        </mat-form-field>
        <mat-icon class="mat-search" svgIcon="search" style="position: relative; top: 18px; left: -23px;"
          aria-hidden="false" aria-label="Ícone de busca">
        </mat-icon>
      </form>

      <div style="overflow-x:auto;" *ngIf="areasAtuacaoControl.value">
        <table mat-table matSort id="grid-home" style="width: 100%;" [dataSource]="dataSource">
          <ng-container matColumnDef="RepresentanteId" style="padding-left: 330px;">
            <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Representante"
              style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              Representante
            </th>
            <td class="reducer" mat-cell *matCellDef="let element" (click)="showDetails(element)"
              matTooltip="{{element.representanteId}}"
              style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              {{element.representanteId}} - {{element.descricao}}
            </td>
          </ng-container>
          <ng-container matColumnDef="Tipo" style="padding-left: 330px;">
            <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Tipo"
              style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              Tipo
            </th>
            <td class="reducer" mat-cell *matCellDef="let element" (click)="showDetails(element)"
              matTooltip="{{element.tipo}}"
              style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              {{element.tipoParceiroDesc ? element.tipoParceiroDesc : element.tipoParceiro}}
            </td>
          </ng-container>
          <ng-container matColumnDef="CpfCnpj" style="padding-left: 330px;">
            <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="CpfCnpj"
              style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              CPF/CNPJ
            </th>
            <td class="reducer" mat-cell *matCellDef="let element" (click)="showDetails(element)"
              matTooltip="{{element.cpfCnpj}}"
              style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              {{ element.cpfCnpj.length === 11 ? mascaraCPF(element.cpfCnpj) : mascaraCNPJ(element.cpfCnpj) }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Nome" style="padding-left: 330px;">
            <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Nome"
              style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              Nome
            </th>
            <td class="reducer" mat-cell *matCellDef="let element" (click)="showDetails(element)"
              matTooltip="{{element.nome}}"
              style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              {{ element.nome }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Situacao" style="padding-left: 330px;">
            <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Situacao"
              style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
              Situação
            </th>
            <td class="reducer situacao" mat-cell *matCellDef="let element" (click)="showDetails(element)"
              matTooltip="{{element.situacao}}"
              [ngClass]="{'recusado': element.situacao === 'CAD.REC', 'realizado': element.situacao === 'CAD.OK', 'enviado': element.situacao === 'CAD.ENV' }">
              {{ element.situacaoDesc ? element.situacaoDesc : element.situacao }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-table"></tr>
        </table>

        <mat-paginator (page)="pageEvent = $event" [length]="preCadastroCount" [pageSize]="pageSize" showFirstLastButtons>
        </mat-paginator>
      </div>
    </div>

    <ng-template #loading>
      <mat-spinner diameter="30" class="loading-spinner"></mat-spinner>
    </ng-template>
  </mat-expansion-panel>
</div>
