<header fxLayout="row" style="width: 100%;">
  <div fxLayout="row" style="width: 100%;">
    <div fxLayout="column" fxLayoutAlign="center center" style="margin:10px">
      <img
        style="padding: 7px;" style="cursor: pointer; border: 0; outline: 0;"
        src="{{logoUrl}}"
        alt=""
        (click)="goHome()"
        (load)="loadBadgeAmbiente()"
      >
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="ambiente.pre !== ''">
      <mat-chip-list>
        <mat-chip
          selected
          [ngStyle]="{'background-color': ambiente.cor}"
          matTooltip="{{ambiente.tooltip}}"
        >
          <span style="text-align: center;">{{ambiente.pre}}</span>
        </mat-chip>
      </mat-chip-list>
    </div>

    <span class="container-header" *ngIf=isLogged>
      <mat-spinner *ngIf="fetchingMenu" diameter="40"></mat-spinner>
      <!-- Percorrendo os itens do menu -->
      <div *ngFor="let item of menu" style="margin-top: 4px;">
        <!-- Caso o item tenha sub-itens -->
        <div *ngIf=" item.subMenu.length > 0">
          <button
            [class]="item.selecionado ? 'selected button-header menuItem': 'button-header menuItem'"
            [id]="item.id" [matMenuTriggerFor]="menuFor"
            routerLinkActive=" router-link-active" mat-button
            *ngIf="visualizarMenu(item.id) && item.ativo"
          >
            <mat-icon class="icon-inv" aria-hidden="false" style="margin-right: 10px;">
              {{item.icon}}
            </mat-icon>
            {{item.titulo}}
          </button>
          <!-- Sub-itens -->
          <mat-menu #menuFor="matMenu">
            <div *ngFor="let subMenu of item.subMenu">
              <button mat-menu-item [routerLink]="[subMenu.rota]" routerLinkActive="router-link-active"
                  *ngIf="visualizarSubMenu(subMenu.id) && subMenu.ativo">{{subMenu.titulo}}</button>
            </div>
          </mat-menu>
        </div>
        <!-- Caso o item seja apenas uma rota -->
        <div *ngIf=" item.subMenu.length === 0">
          <button [class]="item.selecionado ? 'selected button-header menuItem': 'button-header menuItem'"
            [id]="item.id" [routerLink]="[item.rota]"
            *ngIf="visualizarMenu(item.id) && item.ativo"
            routerLinkActive=" router-link-active" mat-button
          >
            <mat-icon class="icon-inv" aria-hidden="false" style="margin-right: 10px;">
              {{item.icon}}
            </mat-icon>
            {{item.titulo}}
          </button>
        </div>
        <!-- Fim menu -->
      </div>
    </span>

    <!-- Menu do usuário -->
    <button mat-flat-button [style.background-color]="btnColor" (click)="login()" *ngIf=!isLogged style="margin: auto;margin-right: 17px;">
      <span>Login</span>
    </button>

    <button mat-button [matMenuTriggerFor]="userMenu" *ngIf=isLogged style="display: flex;margin: auto;margin-right: 17px;">
      <mat-icon class="icon-inv" aria-hidden="false">person</mat-icon>
      <span>{{username}}</span>
      <mat-icon class="icon-inv" aria-hidden="false">keyboard_arrow_down</mat-icon>
    </button>

    <!-- Itens do menu usuário -->
    <mat-menu #userMenu="matMenu">
      <button mat-menu-item [matMenuTriggerFor]="menuTheme">Temas</button>
      <button mat-menu-item [routerLink]="['/logout']" routerLinkActive="router-link-active">Sair</button>
    </mat-menu>

    <!-- Itens tema -->
    <mat-menu #menuTheme="matMenu">
      <button
        *ngFor="let tema of temas" mat-menu-item
        (click)="themeHandler(tema.nome)"
      >
        {{tema.descricao}}
      </button>
    </mat-menu>
  </div>
</header>
