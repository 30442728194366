import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';

import { Campo, DadosGerais, Secao } from '../models/form-stepper';
import { FormStepper } from './../models/form-stepper';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormularioService {
  /** Modo do Formulário **/
  public mode: string = 'C';
  /** Preenchimento form */
  public dadosGerais: DadosGerais = {} as DadosGerais;
  public secoes: Secao[] = [];
  public campos: Campo[] = [];
  /** Validação do formulário de cada etapa **/
  public validFormStep: ValidFormStep = {} as ValidFormStep;
  public onValid: EventEmitter<ValidFormStep> = new EventEmitter();
  /** Formulário original edição **/
  public formStepperEdit: FormStepper;
  /** Formulário Alterado */
  public formChanged: boolean;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Atualiza o valor e a validade do formulário
   * @param step etapa string
   * @param form form group
   */
  public updateValueAndValidity(step: string, form: FormGroup) {
    this.validFormStep[step] = form.valid;
    this.formChanged = !this.formChanged ? true : this.formChanged;
    const formValue = form.getRawValue();
    // Trata os valores de acordo com a etapa
    if (step === 'dadosGerais') {
      this[step] = formValue;
    }
    if (['campos', 'secoes'].includes(step)) {
      // Caso tenha alteração na etapa de campos precisamos aplicar uma alteração na seção
      if (step === 'campos') {
        // Altera o valor pelo id do campo na seção
        this._changeSecaoCampo(formValue[step]);
      }
      this[step] = formValue[step];
    }
    this._setValidFormStep();
  }

  /**
   * Define as regras para que a etapa seja válida
   */
  private _setValidFormStep() {
    const { campos, dadosGerais, secoes } = this.validFormStep;
    const allValid = campos && dadosGerais && secoes;
    this.validFormStep.campos = dadosGerais && campos;

    this.validFormStep.secoes = allValid && this.camposSecoesValid();
    this.validFormStep.preview = this.validFormStep.secoes;
    this.revalidateSecoes();
    this.onValid.emit(this.validFormStep);
  }

  /**
   * Define todas as etapas como válidas
   */
  private _setAllValidStep() {
    this.validFormStep.dadosGerais = true;
    this.validFormStep.campos = true;
    this.validFormStep.secoes = true;
    this.validFormStep.preview = true;
  }

  /**
   * Verifica se os campos na seção são válidos
   * Obs: Precisa ter o mesmo tamanho dos campos cadastrados
   * @returns boolean
   */
  public camposSecoesValid(): boolean {
    const camposSize = this.campos.length;
    const secoesCampos = this.secoes
      .reduce((acum, current) => {
        acum += current.campos.filter((c) => !!c.id).length;
        return acum;
      }, 0);

    const isEqual = camposSize === secoesCampos;
    if (!isEqual) {
      this.validFormStep.secoes = false;
      this.validFormStep.preview = false;
    }

    return isEqual;
  }

  /**
   * Revalidar as seções
   */
  public revalidateSecoes() {
    this.camposSecoesValid();
  }

  /**
   * Apaga o campo da seção
   * @param campo campo
   */
  public deleteCampoSecao(campo: Campo) {
    const dataToDelete = { idxSecao: null, idxCampo: null };
    this.secoes.find((secao, idxSecao) => {
      if (!secao) { return; }
      secao.campos?.find((campoSecao, idxCampo) => {
        if (!!campoSecao && campoSecao?.id === campo?.id) {
          dataToDelete.idxSecao = idxSecao;
          dataToDelete.idxCampo = idxCampo;
        }
      });
    });
    // Apaga o campo da seção
    if (dataToDelete.idxSecao !== null) {
      this.secoes[dataToDelete.idxSecao].campos.splice(dataToDelete.idxCampo, 1);
    }
  }

  /**
   * Altera o valor do campo na seção
   */
  private _changeSecaoCampo(newCampos: Campo[]) {
    // Loop nos campos alterados
    for (const [index, campoNovo] of newCampos.entries()) {
      // Recupera o campo antigo pelo indice
      const oldCampo = this.campos[index];
      // percorre as seções
      this.secoes.forEach((s) => {
        // Encontra o campo e altera o id para o valor novo
        const campoSecao = s.campos.find((c) => c?.id === oldCampo?.id);
        if (campoSecao) {
          campoSecao.id = campoNovo.id;
        }
      })
    }
  }

  /**
   * Método para recuperar os formularios
   * o get está separado do método por se tratar de um accessor, facilitador.
   * @returns observable da api
   */
  public get formularios(): Observable<FormStepper[]> {
    return this.httpClient.get<FormStepper[]>(`${env.API_ENDPOINT}/api/v1/formulario-dinamico/all`);
  }

  /**
   * Envia o documento do formulário para o couchDB
   */
  public async postFormularioDinamico(): Promise<FormStepper> {
    if (!this.validFormStep.preview) {
      throw new Error('Formulário inválido');
    }
    // Chamada da api
    return this.httpClient
      .post(`${env.API_ENDPOINT}/api/v1/formulario-dinamico`, this.normalizedForm)
      .toPromise() as Promise<FormStepper>;
  }

  /**
   *
   * @param formStepper
   */
  public editFormulario(formStepper: FormStepper) {
    this.clear();
    this.formStepperEdit = formStepper;
    this.dadosGerais = formStepper.dadosGerais;
    this.campos = formStepper.campos;
    this.secoes = formStepper.secoes;
    this._setAllValidStep();
  }

  /**
   * Service clear
   */
  public clear() {
    this.dadosGerais = {} as DadosGerais;
    this.secoes = [];
    this.campos = [];
    this.validFormStep = {} as ValidFormStep;
    this.mode = 'C';
    this.formStepperEdit = {} as FormStepper;
    this.formChanged = false;
  }

  /**
   * Recupera o formulário
   */
  public get form(): FormStepper {
    return {
      dadosGerais: this.dadosGerais,
      campos: this.campos,
      secoes: this.secoes
    }
  }

  /**
   * Formulario normalizado
   */
  public get normalizedForm(): FormStepper {
    return {
      _id: this.formStepperEdit?._id || `formularioDinamico-${this.dadosGerais.id}`,
      ...this.form,
      dataCriacao: this.formStepperEdit?.dataCriacao,
      dataModificacao: this.formStepperEdit?.dataModificacao
    }
  }
}


interface ValidFormStep {
  dadosGerais: boolean;
  secoes: boolean;
  campos: boolean;
  preview: boolean;
}
