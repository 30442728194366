import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import { FormArray } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProdutoImagem } from './../../models/produto';

@Component({
  selector: 'app-produto-imagem-dialog',
  templateUrl: './produto-imagem-dialog.component.html',
  styleUrls: ['./produto-imagem-dialog.component.scss']
})
export class ProdutoImagemDialogComponent implements OnInit {
  private urlRegex = /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/;
  dynamicForm: FormGroup;

  constructor(
    // necessário definir a variável no construtor
    @Inject(MAT_DIALOG_DATA) public data: any,
    // referencia do dialog
    public dialogRef: MatDialogRef<ProdutoImagemDialogComponent>,
    // formulário construtor
    private formBuilder: FormBuilder,
    // serviço de alerta
    private toastr: ToastrService) { }

  ngOnInit(): void {
    // Cria o dynamic form
    this.dynamicForm = this.formBuilder.group({
      imagens: new FormArray([]),
    });
    // Popula as imagens
    this.populateImagens();
  }

  /* Getters de conveniência, acessa facilmente os campos do formulário */

  /**
   * Acessa os dados do formulário
   * o get está separado do método por se tratar de um accessor, facilitador.
   * @returns FormControl
   */
  get f() { return this.dynamicForm.controls; }

  /**
   *  Acessa as  imagens dos dados do formulário
   * o get está separado do método por se tratar de um accessor, facilitador.
   * @returns FormArray
   */
  get imagens() { return this.f.imagens as FormArray; }

  /**
   * Preenche as imagens no formulário
   */
  private populateImagens(): void {
    // Percorre as imagens recuperadas do couchDB
    if (this.data.imagens.length > 0) {
      // Percorre os dados enviados pelo open do dialog
      this.data.imagens.forEach((img: any) => {
        // Insere um novo formGroup ao formArray "imagens"
        this.imagens.push(this.formBuilder.group({
          linkId: [img.linkId, [Validators.required, Validators.pattern(this.urlRegex)]],
        }));
      });
    }

  }

  /**
   * Fecha o dialog e retorna null para a promise
   */
  onCancelar(): void {
    this.dialogRef.close(null);
  }

  /**
   * Valida o formulário e em caso de sucesso, retorna os dados preenchidos para a promise
   */
  onSalvar(): void {
    // Seta as imagens na constante
    const imagens = this.dynamicForm.value.imagens;
    // para aqui caso o formulário seja invalido
    if (this.dynamicForm.invalid) {
      this.toastr.error('Preencha todos os campos obrigatórios <br> Lembre-se: O campo URL Imagem precisa ser um URL válido.', 'Alerta!');
      return;
    }
    // para aqui caso tenha uma imagem repetida
    if (this.hasDuplicates(imagens)) {
      return;
    }
    // Fecha o dialog e devolve os dados para a promise aberta
    this.dialogRef.close({
      produtoId: this.data.produtoId,
      imagens
    });
  }

  /**
   * Quando o usuário clica no botão "+", insere mais um campo de "URL Imagem" no formulário
   */
  onClickAdd(): void {
    // Insere uma nova imagem ao formArray
    this.imagens.push(this.formBuilder.group({
      linkId: ['', [Validators.required, Validators.pattern(this.urlRegex)]],
    }));
  }

  /**
   * Quando o usuário clica no botão "-", remove a exata linha "URL Imagem" do formulário
   * @param index posição da imagem a ser removida
   */
  onClickDelete(index: any): void {
    this.imagens.removeAt(index);
  }

  /**
   * Método que verifica se existem imagens com o mesmo URL
   * @param imagens Array de imagens
   * @returns boolean
   */
  hasDuplicates(imagens: any): boolean {
    // Cria um set com valores unicos, no caso se o link se repetir
    const uniqueValues = new Set(imagens.map(v => v.linkId));
    // Compara o tamanho do "Set" com o tamanho original do array de imagens
    if (uniqueValues.size < imagens.length) {
      this.toastr.error('O campo URL Imagem deve ser único, favor não inserir imagens duplicadas.', 'Alerta!');
      return true;
    }
    // Retorna falso caso não tenha duplicados
    return false;
  }


}
