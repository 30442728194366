import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';

import { ToastrModule } from 'ngx-toastr';
import { WINDOW_PROVIDERS } from './window.providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { PrincipalComponent } from './components/principal/principal.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { RepresentanteComponent } from './components/admin/representante/representante.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NovoUsuarioDialogComponent } from './dialogs/novo-usuario-dialog/novo-usuario-dialog.component';
import { AlterarSenhaDialogComponent } from './dialogs/alterar-senha-dialog/alterar-senha-dialog.component';
import { ExcluirUsuarioDialogComponent } from './dialogs/excluir-usuario-dialog/excluir-usuario-dialog.component';
import { MonitorPreCadastroDetalheComponent } from './dialogs/monitor-pre-cadastro-detalhe/monitor-pre-cadastro-detalhe.component';
import { EventosPreCadastroComponent } from './dialogs/eventos-pre-cadastro/eventos-pre-cadastro.component';
import { ProdutoImagemComponent } from './components/admin/produto-imagem/produto-imagem.component';
import { ProdutoImagemDialogComponent } from './dialogs/produto-imagem-dialog/produto-imagem-dialog.component';
import { MonitorPreCadastroComponent } from './components/admin/monitor-pre-cadastro/monitor-pre-cadastro.component';
import { ErroDialogComponent } from './dialogs/erro-dialog/erro-dialog.component';
import { MonitorPreCadastroIntlService } from './components/admin/monitor-pre-cadastro/monitor-pre-cadastro-intl.service';
import { ConfirmacaoExclusaoDialogComponent } from './dialogs/confirmacao-exclusao-dialog/confirmacao-exclusao-dialog.component';

import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalRedirectComponent,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory,
} from './auth-config';
import { FormularioPageModule } from './pages/admin/formularios/formulario.page.module';


registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PrincipalComponent,
    HomeComponent,
    LogoutComponent,
    RepresentanteComponent,
    NovoUsuarioDialogComponent,
    LoginComponent,
    AlterarSenhaDialogComponent,
    ExcluirUsuarioDialogComponent,
    MonitorPreCadastroComponent,
    MonitorPreCadastroDetalheComponent,
    EventosPreCadastroComponent,
    ProdutoImagemComponent,
    ProdutoImagemDialogComponent,
    ErroDialogComponent,
    ConfirmacaoExclusaoDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatSelectModule,
    MatBadgeModule,
    MatPaginatorModule,
    MsalModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      enableHtml: true,
    }),
    BrowserAnimationsModule,
    FormularioPageModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false },
    },
    {
      provide: MatPaginatorIntl,
      useClass: MonitorPreCadastroIntlService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
