<div class="container" fxLayout="column" style="height: 100%; width: 100%;">
    <mat-expansion-panel class="card" [expanded]="true">
        <mat-panel-title>Imagens dos Produtos</mat-panel-title>
        <form action="" fxLayout="row" *ngIf="produtosFiltrados">
            <mat-form-field fxFlex="30%">
                <input matInput placeholder="Pesquisar" value="" (keyup)="applyFilter($event.target.value)">
            </mat-form-field>
            <mat-icon style="position: relative; top: 18px; left: -23px;" aria-hidden="false"
                aria-label="Ícone de busca">search</mat-icon>
        </form>

        <div style="overflow-x:auto;" *ngIf="produtosFiltrados">
            <table mat-table matSort id="grid-home" style="width: 100%;" [dataSource]="produtosFiltrados">
                <ng-container matColumnDef="ProdutoId" style="padding-left: 330px;">
                    <th class="reducer" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Produto"
                        style="width: 5%; padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        Produto
                    </th>
                    <td class="reducer" mat-cell *matCellDef="let element" (click)="openProdutoImagem(element.produtoId)"
                        matTooltip="{{element.produtoId}}"
                        style="padding-right: 5px; margin-right:5px !important; padding-left: 15px !important;">
                        {{element.produtoId}} - {{element.descricao}}
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-table"></tr>
            </table>
        </div>
    </mat-expansion-panel>
</div>
